import * as React from 'react';
import s from './offering-price.st.css';
import {
  CatalogOfferingInjectedProps,
  withOfferingContext,
} from '../../../catalog-offering-context';

class OfferingPrice extends React.Component<CatalogOfferingInjectedProps> {
  render() {
    const { offeringViewModel } = this.props;
    const priceModel = offeringViewModel.price;
    return priceModel ? (
      <div data-hook="tile-price" className={s.root} tabIndex={0}>
        {priceModel}
      </div>
    ) : null;
  }
}

export default withOfferingContext()(OfferingPrice);
