import {
  BookItButtonStyle,
  ImageDisplayType,
  OfferingDisplayOptions,
} from '../business-logic/offering-display-options';
import { OfferingDomain, OfferingType } from './offering-domain';
import primaryInfoStyle from '../components/Offering/OfferingPrimaryInfo/OfferingPrimaryInfo.st.css';
import { PricingPlanInfo, ImageDto } from '@wix/bookings-uou-domain';

export interface IOfferingViewModel {
  alignmentStyle: string;
  title?: string;
  duration?: string;
  price?: string;
  days?: string;
  description?: string;
  image?: ImageViewModel;
  action?: ActionViewModel;
  type?: OfferingType;
  pricingPlanInfo?: PricingPlanInfo;
  id: string;
  displayOnlyNoBookFlow: boolean;
  errorMessageTranslated?: string;
  userMessageOkLabel?: string;
  tileLayout: TileLayout;
}

export interface ImageViewModel {
  type: ImageDisplayType;
  mediaItem: ImageDto;
}

export interface ActionViewModel {
  type: BookItButtonStyle;
  cta: string;
}

export enum TileLayout {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}

export class OfferingViewModelFactory {
  // TODO - work with offering dto, clean keys from returned object
  static createOfferingViewModel(
    offeringDomain: OfferingDomain,
    offeringDisplayOptions: OfferingDisplayOptions,
    formatter: Function,
    tileLayout: TileLayout = TileLayout.HORIZONTAL,
  ): IOfferingViewModel {
    const offeringTitleView = offeringDisplayOptions.getLayout
      .isOfferingNameVisible
      ? offeringDomain.dummy
        ? formatter(offeringDomain.name)
        : offeringDomain.name
      : null;
    const offeringDurationView = offeringDisplayOptions.getLayout
      .isDurationVisible
      ? offeringDomain.durationTextByFormat(formatter)
      : null;
    const offeringPriceView = offeringDisplayOptions.getLayout.isPriceVisible
      ? offeringDomain.priceText
      : null;
    const offeringDaysView =
      offeringDisplayOptions.getLayout.isServiceDaysVisible &&
      offeringDomain.type === OfferingType.GROUP
        ? offeringDomain.daysTextByFormat(formatter)
        : null;
    const offeringDescriptionView = offeringDisplayOptions.getLayout
      .isDescriptionVisible
      ? offeringDomain.description
      : null;
    const errorMessageTranslated = offeringDomain.errorMessageTranslation(
      formatter,
    );
    const userMessageOkLabel = offeringDomain.userMessageButtonLabel(formatter);
    const offeringImageView = {
      type: offeringDisplayOptions.getLayout.imageType,
      mediaItem: offeringDomain.image,
    };
    const offeringActionView = {
      type: offeringDisplayOptions.getLayout.buttonType,
      cta:
        offeringDisplayOptions.getDesign.getButtonText ||
        formatter(
          `settings.design.button.${
            offeringDomain.displayOnlyNoBookFlow ? 'more-info' : 'book-it'
          }`,
        ),
    };
    const offeringAlignmentStyle =
      primaryInfoStyle[
        `${offeringDisplayOptions.getLayout.textAlignment}-aligned`
      ];
    return {
      alignmentStyle: offeringAlignmentStyle,
      title: offeringTitleView,
      duration: offeringDurationView,
      price: offeringPriceView,
      days: offeringDaysView,
      description: offeringDescriptionView,
      image: offeringImageView,
      action: offeringActionView,
      type: offeringDomain.type,
      pricingPlanInfo: offeringDomain.pricingPlanInfo,
      id: offeringDomain.id,
      displayOnlyNoBookFlow: offeringDomain.displayOnlyNoBookFlow,
      errorMessageTranslated,
      userMessageOkLabel,
      tileLayout,
    };
  }

  // TODO - create private static methods and transfer logic from offering domain to here.
}
